/**
 * Attendre que le CSS soit chargé dans le dom avant de révéler le corp de la page.
 */
document.onreadystatechange = () => {
  if(document.readyState === "complete") {
    document.body.style.visibility = "visible";
  }
}

/**
 * Required CSS
 */
require('normalize.css/normalize.css');
require('../css/normalize-fixes.css');
require('../css/themes/stable.css');
require('../css/themes/classy.css');
require('../css/bootstrap.css');
require('../icomoon/style.css');
require('../css/style.css');

/**
 * Required Fonts
 */
// TODO à vérifier, avant on avait enedis.eot
require('../icomoon/fonts/enedis-icon.eot');
