require("../require");
require("/node_modules/chart.js/dist/Chart.js");
require("./page.css");

import { siOnEstEnPeriodeHivernale } from "../../js/services/passage-hiver";
import { huitabSettings } from "../../js/services/settings-loader";

if (siOnEstEnPeriodeHivernale()) {
  // Affiche l'encart quand on est dans l'interval de l'hiver
  document.querySelector("#encartHiver").hidden = false;
} else {
  // Enlève l'encart quand on est en dehors de l'interval de l'hiver
  document.querySelector("#encartHiver").hidden = true;
}

// Feature flipping rationalisation mes-demandes-colloc
if (huitabSettings.feature_flipping.rationalisation_mes_demandes_colloc_enabled) {
  document
    .querySelector("div[is='micro-app-widget-aide-et-contact']")
    .setAttribute("data-url", "/mon-compte-colloc/private-widget-interlocuteurs");
  document
    .querySelector("div[is='micro-app-widget-aide-et-contact']")
    .setAttribute("data-url-vie", "/mon-compte-colloc/actuator/info");
} else {
  document
    .querySelector("div[is='micro-app-widget-aide-et-contact']")
    .setAttribute("data-url", "/mes-demandes-colloc/private-widget-interlocuteurs");
  document
    .querySelector("div[is='micro-app-widget-aide-et-contact']")
    .setAttribute("data-url-vie", "/mes-demandes-colloc/actuator/info");
}
